import type React from 'react';
import { useEffect, useRef } from 'react';
import 'odometer/themes/odometer-theme-default.css';

// Dynamically import Odometer (because it's a browser-side library)
const Odometer = typeof window !== 'undefined' ? require('odometer') : null;

interface OdometerProps {
  value: number;
}

const OdometerComponent: React.FC<OdometerProps> = ({ value }) => {
  const odometerRef = useRef<HTMLDivElement>(null);
  const odometerInstance = useRef<any>(null);

  useEffect(() => {
    if (odometerRef.current && Odometer) {
      odometerInstance.current = new Odometer({
        el: odometerRef.current,
        value: 0,
        format: '(,ddd).dd',
        theme: 'default',
      });
    }

    return () => {
      if (odometerInstance.current) {
        // Clean up the Odometer instance if needed
      }
    };
  }, []);

  useEffect(() => {
    if (odometerInstance.current) {
      odometerInstance.current.update(value);
    }
  }, [value]);

  return (
    <div className="flex items-center">
      $ <div ref={odometerRef} />
    </div>
  );
};

export default OdometerComponent;
