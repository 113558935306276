import { useMemo } from 'react';
import { usePlayerLimits } from '@/hooks/limits';
import useCountdown from '@/hooks/use-countdown';
import { useTranslation } from 'react-i18next';
import { GeneralContentType } from '@/types/json-content/general';
import { SessionLimit, UserLimit } from '@/types/user';

function useCoolOffMessage() {
  const { data: limits } = usePlayerLimits();
  const { t } = useTranslation();
  const generalContent = t('general') as GeneralContentType;
  const restrictedPage = generalContent?.restricted_page;

  const coolingOffLimit: UserLimit | SessionLimit | undefined = useMemo(() => {
    const limit = limits?.find(
      (limit) =>
        limit.type === 'coolingoff' && limit.status === 'confirmed_disable',
    );
    return limit;
  }, [limits]);

  const { state } = useCountdown({
    short: false,
    date: coolingOffLimit?.disable_at,
  });

  const coolOffMessage = useMemo(() => {
    if (!coolingOffLimit) return '';

    return `${restrictedPage?.reason?.cool_off || 'You are in a cooling-off period. Try again in '} ${state.hours} hours, ${state.minutes} minutes, ${state.seconds} seconds.`;
  }, [
    coolingOffLimit,
    restrictedPage?.reason?.cool_off,
    state.hours,
    state.minutes,
    state.seconds,
  ]);

  return { coolingOffLimit, coolOffMessage };
}

export default useCoolOffMessage;
