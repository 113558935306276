import { cn } from '@/utils/style';
import React from 'react';

type Props = {
  color: string;
  className?: string;
  children: React.ReactNode;
};

const CryptoIconWrapper: React.FC<Props> = ({
  color,
  className,
  children,
  ...rest
}) => {
  return (
    <div
      className={cn(['rounded-full [&>svg]:h-3 [&>svg]:w-3', className, color])}
      {...rest}
    >
      {children}
    </div>
  );
};

export default CryptoIconWrapper;
