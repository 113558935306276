import React from 'react';
import { OverlayCurrencySelector } from './overlay-currency-selector';
import { cn } from '@/utils/style';
import { useTranslation } from '@/app/i18n/client';
import { GamesContentType } from '@/types/json-content/games';
import { useAuth } from '@/hooks/auth';

const BalanceSelector = ({
  className,
  forDesktop = false,
  gameViewIndex = 0,
}: {
  forDesktop?: boolean;
  className?: string;
  gameViewIndex?: number;
}) => {
  const {
    user: { isAuthenticated },
  } = useAuth();
  const { t } = useTranslation();
  const gamesContent = t('games') as GamesContentType;

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div
      className={cn([
        'z-[99] flex flex-row  items-center space-x-2 text-sm font-bold',
        className,
      ])}
    >
      <span
        className={cn([
          'whitespace-nowrap text-sm font-semibold text-text-default',
          forDesktop && 'text-white',
        ])}
      >
        {gamesContent?.game?.overlay?.play_mode?.label}
      </span>
      <OverlayCurrencySelector
        forDesktop={forDesktop}
        gameViewIndex={gameViewIndex}
      />
    </div>
  );
};

export default BalanceSelector;
