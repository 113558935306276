import React, { useMemo } from 'react';
import Image from 'next/image';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { ListComponent, Text, TooltipPopover } from '@purposeinplay/core-v2';
import { TextLoader } from '@/components/Loaders';
import { cn } from '@/utils/style';
import {
  getJackpotBgByIdentifier,
  getJackpotTypeByIdentifier,
  getLabelColorByIdentifier,
} from '@/modules/jackpot/main-section/utils';
import { useTranslation } from '@/app/i18n/client';
import { JackpotContentType } from '@/types/json-content/jackpot';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import OdometerComponent from '@/components/Odometer';
import { useJackpots } from '@/hooks/jackpot';

type LevelIdentifier = 'level_1' | 'level_2' | 'level_3';

const orderMap: Record<LevelIdentifier, number> = {
  level_1: 2,
  level_2: 1,
  level_3: 0,
};

interface JackpotDetailViewProps {
  isInTooltip?: boolean;
  onBack?: () => void;
}

const JackpotDetailView: React.FC<JackpotDetailViewProps> = ({
  isInTooltip,
  onBack,
}) => {
  const { t } = useTranslation();
  const contentJackpot = t('jackpot') as JackpotContentType;
  const content = contentJackpot?.main_section;
  const { formatCurrency } = useCurrencyFormatter();

  // Fetch jackpot data
  const { data, isLoading } = useJackpots();

  // Find active jackpot
  const activeJackpot = useMemo(
    () =>
      data?.find(
        (item) =>
          item?.state === 'active' &&
          item?.name === process?.env?.NEXT_PUBLIC_JACKPOT_NAME,
      ),
    [data],
  );

  // Sort jackpot levels
  const activeJackpotLevels = useMemo(
    () =>
      activeJackpot?.levels?.sort(
        (a, b) =>
          orderMap[a.identifier as LevelIdentifier] -
          orderMap[b.identifier as LevelIdentifier],
      ),
    [activeJackpot],
  );

  // Loader
  if (isLoading || !activeJackpotLevels) {
    return (
      <div
        style={{
          background: 'linear-gradient(274deg, #7B25A1 -7.46%, #423292 75.63%)',
        }}
        className={cn([
          'flex w-full flex-col rounded-lg p-1',
          isInTooltip && 'p-2',
        ])}
      >
        <div className={cn(['flex flex-col gap-1'])}>
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={`skeleton-${index}`}
              className={cn([
                'flex items-center gap-3 rounded-lg p-1',
                index === 1 && 'bg-surface-subdued',
                'animate-pulse',
              ])}
            >
              {/* Badge placeholder */}
              <div className="relative flex-shrink-0">
                <TextLoader className="h-10 w-10 !rounded-full" />
              </div>

              <div className="flex flex-grow items-center justify-between gap-3">
                <div className="flex flex-grow flex-col items-start">
                  {/* Jackpot name placeholder */}
                  <TextLoader className="mb-1 h-4 w-20" />

                  {/* Hit range placeholder */}
                  <div className="flex items-center">
                    <TextLoader className="mr-1 h-3 w-16" />
                    <TextLoader className="h-3 w-8" />
                  </div>
                </div>

                {/* Odometer amount placeholder */}
                <TextLoader className="h-6 w-24" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        background: 'linear-gradient(274deg, #7B25A1 -7.46%, #423292 75.63%)',
      }}
      className={cn([
        'flex w-full flex-col rounded-lg p-1',
        isInTooltip && 'p-2',
      ])}
    >
      <div className={cn(['flex flex-col gap-1'])}>
        {activeJackpotLevels.map((item, index) => {
          const fiatAmount = formatCurrency(item?.amount_cents, 'USD');
          const odometerAmount = Number.parseFloat(
            fiatAmount.replace(/[^0-9.]/g, ''),
          );

          const hitRange = content?.jackpot_values?.find(
            (val) =>
              val?.id.toUpperCase() ===
              getJackpotTypeByIdentifier(item.identifier)?.toUpperCase(),
          )?.hit_range;

          return (
            <div
              key={`${item?.id}-${index}`}
              className={cn([
                'flex items-center gap-3 rounded-lg  p-1',
                index === 1 && 'bg-surface-subdued',
              ])}
            >
              <div className="relative flex-shrink-0">
                <Image
                  src={`/assets/jackpot/${getJackpotBgByIdentifier(item.identifier)}-badge.png`}
                  width={40}
                  height={40}
                  alt="Jackpot badge box"
                  loader={DEFAULT_IMAGE_LOADER}
                  className="h-10 w-10 object-contain"
                />
              </div>

              <div className="flex flex-grow items-center justify-between gap-3">
                <div className="flex flex-grow flex-col items-start">
                  <Text
                    size="xs"
                    as="span"
                    className={cn([
                      'whitespace-nowrap font-semibold uppercase leading-none',
                      getLabelColorByIdentifier(item.identifier),
                    ])}
                  >
                    {item?.name}
                  </Text>

                  <div className="flex items-center">
                    <Text
                      size="xs"
                      className="mr-1 whitespace-nowrap font-semibold text-text-subdued"
                    >
                      Hit Range
                    </Text>
                    <Text
                      size="xs"
                      as="span"
                      className={cn([
                        'whitespace-nowrap font-semibold',
                        getLabelColorByIdentifier(item.identifier),
                      ])}
                    >
                      {hitRange || '0'}
                    </Text>
                  </div>
                </div>
                <div className="flex items-center whitespace-nowrap text-sm font-semibold leading-6">
                  <OdometerComponent value={odometerAmount} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default JackpotDetailView;
