'use client';

import { useParams } from 'next/navigation';
import { GameFilterSEOPayload } from '@/types/games_filters';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { isMobile } from 'mobile-device-detect';
import { fetchGamesBySEO } from '@/modules/games/utils';

export const useGamesBySEO = (
  filters: GameFilterSEOPayload = {} as GameFilterSEOPayload,
  options = {},
): UseQueryResult<any> => {
  const platform = isMobile ? 'mobile' : 'desktop';
  const OGGameTitle = useParams()?.id;
  return useQuery({
    queryKey: [
      'filteredGamesBySEO',
      { device: platform as 'mobile' | 'desktop' | undefined, ...filters },
      OGGameTitle,
    ],
    queryFn: async ({ queryKey }: any) => {
      let res;
      res = await fetchGamesBySEO(queryKey[1]);
      if (!res || (!res.length && queryKey[2])) {
        res = await fetchGamesBySEO({
          game_seo_titles: [queryKey[2]],
        });
      }
      return res;
    },
    ...options,
  });
};
