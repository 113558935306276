'use client';
import { useTranslation } from '@/app/i18n/client';
import { GeneralContentType } from '@/types/json-content/general';
import React from 'react';
import EmptyState from '../EmptyState';
import { cn } from '@/utils/style';
import useCoolOffMessage from './hooks/use-cooloff-message';

const DESCRIPTION_FALLBACK =
  "Unfortunately, access is restricted due to one of the following reasons: an existing limit, an active bonus, or regional restrictions. If you're encountering this issue while traveling, consider using a VPN for online safety. For any questions or further assistance, please contact our Support Team.";

const RestrictedComponent = ({
  className,
  reason = '',
  isMinimized = false,
}: {
  className?: string;
  reason?: string;
  isMinimized?: boolean;
}) => {
  const { t } = useTranslation();
  const generalContent = t('general') as GeneralContentType;
  const restrictedPage = generalContent?.restricted_page;

  const { coolingOffLimit, coolOffMessage } = useCoolOffMessage();

  return (
    <div
      className={cn([
        'flex h-screen flex-col items-center justify-center bg-bgr-modal',
        className,
      ])}
    >
      <EmptyState
        className={cn([
          'border-none md:border-border-general-default dark:[&>div>h4]:text-white [&>div>p]:dark:!text-text-subdued dark:[&>div>p]:!text-white',
          isMinimized && '[&>div>div]:hidden  [&>div>p]:!text-[10px]',
        ])}
        title={restrictedPage?.title ?? 'Oh no...'}
        description={
          coolingOffLimit
            ? coolOffMessage
            : // @ts-ignore
              (restrictedPage?.reason?.[reason] ??
              // @ts-ignore
              restrictedPage?.reason?.general_restriction ??
              DESCRIPTION_FALLBACK)
        }
      />
    </div>
  );
};

export default RestrictedComponent;
