import Link from 'next/link';
import HouseSVG from 'public/assets/house.svg';

export const GAME_CATEGORIES = [
  'top',
  'fresh',
  'table-games',
  'bonus-buy',
  'live-casino',
  'skill-games',
  'vip-games',
  'blackjack',
  'slots',
];

export const RESTRICTED_PLACEHOLDER = {
  title: 'Unavailable Game',
  provider: 'N/A',
  uniq_seo_title: false,
  seo_title: 'unavailable-game',
  identifier: 'N/A',
  lines: 0,
  ways: 0,
  volatility_rating: 'low',
  hit_rate: '0%',
  payout: '0%',
  slug: 'unavailable-game',
  id: -1,
  currencies: {},
  unfinished_games_for: [],
  categories: [],
  devices: [],
  url: '',
  status: undefined,
};

export const DEFAULT_BREADCRUMBS = [
  {
    item: {
      id: '0',
    },
    iconStyles: 'stroke-current text-icons-subdued ',
    icon: (
      <Link href="/casino">
        <div className="-translate-y-[1px] cursor-pointer  hover:[&>svg]:text-text-default">
          <HouseSVG />
        </div>
      </Link>
    ),
    active: false,
  },
  {
    item: {
      id: 'providers',
      label: 'Providers',
      href: '/providers',
    },
    active: false,
    dividerHidden: true,
  },
];

export const CLOSED_PATHS = ['/games/recent', '/games/favorites'];
