import React, { Suspense, memo, useCallback, useMemo } from 'react';
import { IconButton } from '@purposeinplay/core-v2';
import { useAuth } from '@/hooks/auth';
import PlaySVG from 'public/assets/play-arrow.svg';
import BalanceSelector from '@/modules/games/components/page-frame/balance-selector';
import {
  setGameViewOpen,
  setIsFun,
  setIsFunForGameView,
} from '@/app/reducers/bootstrap';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import useModal from '@/hooks/modal';
import { cn } from '@/utils/style';
import { useTranslation } from '@/app/i18n/client';
import { GamesContentType } from '@/types/json-content/games';
import { ButtonLoader } from '@/components/Loaders';
import { useScreenLayout } from '../../context/screen-layout-context';
import { useAccounts } from '@/hooks/accounts';

interface GameViewActionBtnProps {
  gameViewIndex: number;
  isFun: boolean;
}

const GameViewActionBtn = ({
  gameViewIndex,
  isFun,
}: GameViewActionBtnProps) => {
  const { t } = useTranslation();
  const gamesContent = t('games') as GamesContentType;
  const gamesButtonContent = gamesContent?.game?.overlay?.play_mode?.action;
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { open } = useModal();
  const { toggleIsFunForIndex, setGameViewOpenForIndex } = useScreenLayout();
  const { account } = useAccounts();

  const handleClick = useCallback(() => {
    if (!user?.isAuthenticated) {
      open('signup', { overlay: 'true' });
    } else {
      if (gameViewIndex === 0) {
        isFun && dispatch(setIsFun(false));
        dispatch(setGameViewOpen(false));
      } else {
        if (isFun) {
          toggleIsFunForIndex(gameViewIndex, account?.currency);
        }
        setGameViewOpenForIndex(gameViewIndex, false);
      }
    }
  }, [
    dispatch,
    isFun,
    open,
    user?.isAuthenticated,
    gameViewIndex,
    toggleIsFunForIndex,
    setGameViewOpenForIndex,
    account?.currency,
  ]);

  return (
    <IconButton
      className="w-full whitespace-nowrap"
      iconLeft={<PlaySVG />}
      onClick={handleClick}
    >
      {user?.isAuthenticated
        ? gamesButtonContent?.primary_auth
        : gamesButtonContent?.primary_guest}
    </IconButton>
  );
};

interface CurrencySelectorOverlayProps {
  gameViewIndex?: number;
}

const CurrencySelectorOverlay = memo(
  ({ gameViewIndex = 0 }: CurrencySelectorOverlayProps) => {
    const dispatch = useAppDispatch();
    const { user } = useAuth();
    const { t } = useTranslation();
    const { getGameForIndex, toggleIsFunForIndex, setGameViewOpenForIndex } =
      useScreenLayout();
    const { account } = useAccounts();
    const gamesContent = t('games') as GamesContentType;
    const gamesButtonContent = gamesContent?.game?.overlay?.play_mode?.action;

    const bootstrap = useAppSelector((state) => state.bootstrap);
    const secondaryGame = getGameForIndex(gameViewIndex);

    const gameState = useMemo(() => {
      if (gameViewIndex === 0) {
        return {
          isMinimized: bootstrap.isMinimized,
          gameViewOpen: bootstrap.gameViewOpen,
          gameView: bootstrap.gameViews?.[0] || null,
          isFun: bootstrap.isFun,
        };
      } else {
        return {
          isMinimized: false,
          gameViewOpen: secondaryGame.isGameViewOpen,
          gameView: secondaryGame.game,
          isFun: secondaryGame.isFun,
        };
      }
    }, [gameViewIndex, bootstrap, secondaryGame]);

    const { isMinimized, gameViewOpen, gameView, isFun } = gameState;

    const handleOverlay = useCallback(() => {
      if (gameViewIndex === 0) {
        !isFun && dispatch(setIsFun(true));
        dispatch(setGameViewOpen(false));
      } else {
        if (!isFun) {
          toggleIsFunForIndex(gameViewIndex, account?.currency);
        }

        setGameViewOpenForIndex(gameViewIndex, false);
      }
    }, [
      dispatch,
      isFun,
      gameViewIndex,
      toggleIsFunForIndex,
      setGameViewOpenForIndex,
      account?.currency,
    ]);

    if (!gameViewOpen || isMinimized || gameView?.status === 'restricted') {
      return null;
    }

    return (
      <div className="absolute inset-0 z-30 flex h-full w-full flex-col items-center justify-center bg-[#111827e6]  object-cover text-black ">
        <div
          className={cn([
            'flex flex-col items-center justify-center',
            user?.isAuthenticated && 'space-y-3',
          ])}
        >
          {user?.isAuthenticated && (
            <BalanceSelector forDesktop gameViewIndex={gameViewIndex} />
          )}
          <div className="relative z-0 flex w-full space-x-2">
            <IconButton
              className="w-full whitespace-nowrap bg-gray-800 text-white hover:bg-gray-700"
              color="tertiary"
              iconLeft={<PlaySVG />}
              onClick={handleOverlay}
            >
              {gamesButtonContent?.secondary}
            </IconButton>
            <Suspense fallback={<ButtonLoader />}>
              <GameViewActionBtn gameViewIndex={gameViewIndex} isFun={isFun} />
            </Suspense>
          </div>
        </div>
      </div>
    );
  },
);

CurrencySelectorOverlay.displayName = 'CurrencySelectorOverlay';

export default CurrencySelectorOverlay;
