import { useQuery, UseQueryResult } from '@tanstack/react-query';
import client from '@/utils/api';
import { JackpotItem, JackpotWinner } from '@/modules/jackpot/types';

const useJackpotWins = (
  jackpotLevel?: string,
): UseQueryResult<JackpotWinner[]> => {
  const queryString = `jackpot_name=${process?.env?.NEXT_PUBLIC_JACKPOT_NAME}${jackpotLevel ? `&jackpot_level_name=${jackpotLevel}` : ''}`;

  return useQuery({
    queryKey: ['jackpot_wins', jackpotLevel],
    queryFn: () => client(`jackpot_wins/?${queryString}`),
    // queryFn: () => client(`jackpot_wins`),
  });
};

const useJackpots = (): UseQueryResult<JackpotItem[]> => {
  return useQuery({
    queryKey: ['jackpots'],
    queryFn: () => client('jackpots', { isV2: true }),
    // queryFn: () => client('jackpots'),
  });
};

export { useJackpotWins, useJackpots };
