import { useAuth } from '@/hooks/auth';
import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { Transition } from '@headlessui/react';
import NoCurrency from './no-currency';
import RealOnly from './real-only';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setOpenGameOverlay } from '@/app/reducers/bootstrap';
import { cn } from '@/utils/style';
import { useScreenLayout } from '../../context/screen-layout-context';
import { useAccounts } from '@/hooks/accounts';

interface RestrictedGameOverlayProps {
  gameViewIndex?: number;
}

const RestrictedGameOverlay = ({
  gameViewIndex = 0,
}: RestrictedGameOverlayProps) => {
  const timeout = useRef<any>();
  const {
    user: { isAuthenticated },
  }: any = useAuth();
  const { layout } = useScreenLayout();
  const isSingle = layout === 'single';

  const dispatch = useAppDispatch();
  const { getGameForIndex, setGameStatusForIndex, toggleIsFunForIndex } =
    useScreenLayout();
  const { account } = useAccounts();

  const bootstrap = useAppSelector((state) => state.bootstrap);
  const secondaryGame = getGameForIndex(gameViewIndex);

  const gameState = useMemo(() => {
    if (gameViewIndex === 0) {
      return {
        isMinimized: bootstrap.isMinimized,
        viewFiat: bootstrap.viewFiat,
        game: bootstrap.gameViews?.[0] || null,
        openGameOverlay: bootstrap.openGameOverlay,
        isFun: bootstrap.isFun,
      };
    } else {
      return {
        isMinimized: false,
        viewFiat: false,
        game: secondaryGame.game,
        openGameOverlay: secondaryGame.isGameViewOpen,
        isFun: secondaryGame.isFun,
      };
    }
  }, [gameViewIndex, bootstrap, secondaryGame]);

  const { isMinimized, viewFiat, game, openGameOverlay, isFun } = gameState;

  useEffect(() => {
    if (gameViewIndex === 0) {
      timeout.current = setTimeout(() => {
        if (!game?.status || game?.status === 'fiat-play') {
          dispatch(setOpenGameOverlay(false));
          return;
        }
        dispatch(setOpenGameOverlay(true));
      }, 500);
    } else {
      timeout.current = setTimeout(() => {
        if (!game?.status || game?.status === 'fiat-play') {
          setGameStatusForIndex(gameViewIndex, null);
          return;
        }
        setGameStatusForIndex(gameViewIndex, game.status as any);
      }, 500);
    }

    return () => clearTimeout(timeout?.current);
  }, [
    game,
    isAuthenticated,
    viewFiat,
    dispatch,
    gameViewIndex,
    setGameStatusForIndex,
  ]);

  const overlayContent = useMemo(() => {
    if (isAuthenticated && game?.status === 'no-currency') {
      return <NoCurrency game={game} />;
    }
    if (isAuthenticated && game?.status === 'real-only') {
      return <RealOnly />;
    }
  }, [game, isAuthenticated]);

  if (!game || game?.status === 'fiat-play') return null;

  if (game?.status === 'restricted') {
    return null;
  }

  return (
    <div
      className={cn([
        'absolute inset-0 flex items-center justify-center bg-[#111827e6]',
        openGameOverlay && `z-20`,
        !openGameOverlay && `hidden`,
        !isMinimized && isSingle && `rounded-tl-3xl rounded-tr-3xl`,
      ])}
    >
      <Transition
        show={openGameOverlay}
        enter={`transition-all transform-gpu ease-out duration-300`}
        enterFrom={`opacity-0 scale-0`}
        enterTo={`opacity-100 scale-100`}
        leave={`transition-all ease-in duration-300`}
        leaveFrom={`opacity-100 scale-100`}
        leaveTo={`opacity-0 scale-0`}
        className={cn([
          'flex h-full min-h-full flex-1 items-center overflow-visible ',
          isMinimized && `scale-[0.27] transform-gpu rounded-tl-none`,
        ])}
      >
        {overlayContent}
      </Transition>
    </div>
  );
};

export default RestrictedGameOverlay;
