import React from 'react';
import { Select } from '@purposeinplay/core-v2';
import useSelectItems from '@/hooks/use-select-items';
import { cn } from '@/utils/style';
import { useTranslation } from '@/app/i18n/client';
import { GamesContentType } from '@/types/json-content/games';
import { TextLoader } from '@/components/Loaders';

export const OverlayCurrencySelector = ({
  forDesktop = false,
  gameViewIndex = 0,
}: {
  forDesktop?: boolean;
  gameViewIndex?: number;
}) => {
  const items = useSelectItems(gameViewIndex);

  const { t } = useTranslation();
  const gamesContent = t('games') as GamesContentType;
  const gamesButtonContent = gamesContent?.game?.overlay?.play_mode?.action;

  const handleSelect = (item: {
    id: string;
    component: React.ReactNode;
    onItemClick: () => void;
  }) => {
    item.onItemClick();
  };

  const defaultSelectedIndex = items.findIndex((item) => item.id === 'USD');

  if (defaultSelectedIndex === -1) {
    return (
      <TextLoader className="h-10 w-[109px] bg-surface-default lg:w-[109px]" />
    );
  }

  return (
    <div className={cn(['w-auto', forDesktop && 'w-full'])}>
      <Select
        defaultSelectedIndex={defaultSelectedIndex}
        stylingOptions={{
          triggerStyles: cn([
            'w-auto bg-bgr-default w-auto relative',
            forDesktop && 'w-full',
          ]),
        }}
        dropdownStylingOptions={{
          contentStyles: 'w-auto',
        }}
        placeholder={gamesButtonContent?.select_currency}
        items={items}
        onSelect={handleSelect}
      />{' '}
    </div>
  );
};
