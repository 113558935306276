import { useGamesByCurrency } from '@/hooks/games';
import Link from 'next/link';
import React from 'react';
import { Game } from '@/types/games';
import PlaySVG from 'public/assets/play-new.svg';
import { Text } from '@purposeinplay/core-v2';
import Image from 'next/image';
import config from '@/config';

const RealOnly = () => {
  const gamesByCurrency = useGamesByCurrency('FUN');
  return (
    <div className="relative z-20 flex h-full flex-1 items-center justify-center">
      <div className="flex-grow p-4 py-10 sm:max-w-md md:max-w-sm md:flex-grow md:py-20">
        <Text className="mb-4 text-center font-medium text-text-default">
          The current game is Real Play only
        </Text>
        {gamesByCurrency?.length > 0 && (
          <div className="mt-7 flex flex-col items-center justify-center">
            <Text className="mb-7">Want to try more games for fun?</Text>
            <div className="flex items-center justify-center">
              {gamesByCurrency.map((game: Game) => (
                <Link
                  key={`game-${game.id}`}
                  href={`/games/${game.slug}`}
                  passHref
                  prefetch={false}
                >
                  <div className="hover:[.gamecard-overlay]:opacity-100 relative mx-3 block h-14 w-14 overflow-hidden rounded-lg">
                    <div className="gamecard-overlay wild-transition absolute inset-0 z-10 flex cursor-pointer  items-center justify-center rounded-lg bg-black bg-opacity-70 opacity-0">
                      <span className="wild-transition scale-75 transform">
                        <PlaySVG />
                      </span>
                    </div>
                    <Image
                      width={56}
                      height={56}
                      className="h-full w-full scale-110 transform-gpu rounded-xl transition duration-150 ease-in-out"
                      src={`${config.SITE_URL}/thumbnail/${
                        game?.identifier.split('/')[0]
                      }/${game?.identifier.split('/')[1]}.webp`}
                      alt="thumbnail"
                    />
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RealOnly;
