import { useUpdatePlayerAccount } from '@/hooks/accounts';
import { useCurrency } from '@/hooks/currencies';
import { useGamesByCurrency } from '@/hooks/games';
import Link from 'next/link';
import React from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { FilteredGame } from '@/types/games_filters';
import { getColorByCurrency, getIconByCurrency } from '@/utils/helpers';
import PlaySVG from 'public/assets/play-new.svg';
import CryptoIconWrapper from '@/components/CryptoIconWrapper';
import { Select, Text } from '@purposeinplay/core-v2';
import Image from 'next/image';
import config from '@/config';

const NoCurrency = ({ game }: { game: FilteredGame }) => {
  const { mutateAsync: updateAccount } = useUpdatePlayerAccount();
  const accountCurrency = useCurrency();
  const gamesByCurrency = useGamesByCurrency(accountCurrency?.code);
  const availableCurrencies = useMemo(
    () =>
      Object.keys(game.currencies)
        .filter((currency: string) => currency !== 'FUN' && currency !== 'USD')
        .map((currency: string) => ({
          id: currency,
          component: (
            <div className="flex flex-row items-center">
              <CryptoIconWrapper
                className="mr-1 flex h-6 w-6 items-center justify-center [&>svg]:h-6 [&>svg]:w-6"
                color={getColorByCurrency(currency.toLowerCase())}
              >
                {getIconByCurrency(currency.toLowerCase())}
              </CryptoIconWrapper>
              {currency}
            </div>
          ),
        })),
    [game],
  );
  const [currency, setCurrency] = useState(availableCurrencies[0]);
  return (
    <div className="relative z-20 z-[99] flex h-full flex-1 items-center justify-center">
      <div className="flex-grow p-4 py-10 sm:max-w-md md:max-w-sm md:flex-grow md:py-20">
        <Text className="mb-4 text-center font-medium dark:text-white">
          The current game does not support your currency, please select one of
          the supported currencies:
        </Text>
        <div className="mx-auto w-1/2">
          <Select
            items={availableCurrencies}
            multi={false}
            onSelect={(currency: any) => {
              setCurrency(currency);
              updateAccount(currency?.value);
            }}
          />
        </div>
        {gamesByCurrency?.length > 0 && (
          <div className="mt-7 flex flex-col items-center justify-center">
            <Text className="mb-7">Want to keep playing in your currency?</Text>
            <div className="flex items-center justify-center">
              {gamesByCurrency.map((game: any) => (
                <Link
                  key={`game-${game.id}`}
                  href={`/games/${game.slug}`}
                  prefetch={false}
                >
                  <div className="hover:[.gamecard-overlay]:opacity-100 relative mx-3 block h-14 w-14 overflow-hidden rounded-lg">
                    <div className="gamecard-overlay wild-transition absolute inset-0 z-10 flex cursor-pointer  items-center justify-center rounded-lg bg-black bg-opacity-70 opacity-0">
                      <span>
                        <PlaySVG />
                      </span>
                    </div>
                    <Image
                      alt="Game thumbnail"
                      fill
                      className="h-full w-full rounded-xl transition duration-150 ease-in-out"
                      src={`${config.SITE_URL}/thumbnail/${
                        game?.identifier.split('/')[0]
                      }/${game?.identifier.split('/')[1]}.webp`}
                    />
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NoCurrency;
