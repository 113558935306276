import { useGamesBySEO } from '@/hooks/games/seo';
import { useMemo } from 'react';
import { FilteredGame } from '@/types/games_filters';
import { isMobile } from 'mobile-device-detect';
import { useParams } from 'next/navigation';

const useSeoGameByTitle = (seoTitle?: string) => {
  const params = useParams();
  // Use provided seoTitle if available, otherwise fall back to URL param
  const gameTitle = seoTitle || (params?.id as string);

  const { data, isLoading } = useGamesBySEO({
    game_seo_titles: [gameTitle],
    without_territorial_restrictions: true,
  });

  const platform = isMobile ? 'mobile' : 'desktop';

  const game = useMemo(() => {
    return data?.find((game: FilteredGame) =>
      game?.devices?.includes(platform),
    );
  }, [data, platform]);

  return { game, isLoading };
};

export default useSeoGameByTitle;
