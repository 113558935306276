// useFavoriteGame.js
import { useCallback } from 'react';
import { useAuth } from '@/hooks/auth';
import {
  useAddToFavorites,
  useRemoveFromFavorites,
  useFavoriteGamesData,
} from '@/hooks/games/favorites';
import useModal from '@/hooks/modal';
import { FilteredGame } from '@/types/games_filters';

const useHandleFavoriteGame = (game: FilteredGame | undefined) => {
  const { user } = useAuth();
  const { open } = useModal();
  const { favoriteGameIdentifiers } = useFavoriteGamesData();
  const { mutateAsync: addToFavorites, isPending: isAddingToFavorites } =
    useAddToFavorites();
  const {
    mutateAsync: removeFromFavorites,
    isPending: isRemovingFromFavorites,
  } = useRemoveFromFavorites();

  const isFavorited = favoriteGameIdentifiers?.includes(game?.identifier);
  const isMutating = isAddingToFavorites || isRemovingFromFavorites;

  const handleFavorites = useCallback(() => {
    if (!game) return;

    if (!user?.isAuthenticated) {
      open('signin');
      return;
    }

    const action = isFavorited ? removeFromFavorites : addToFavorites;
    action({
      gameID: game.id,
      completeGame: game,
    });
  }, [addToFavorites, game, isFavorited, open, removeFromFavorites, user]);

  return { isFavorited, handleFavorites, isMutating };
};

export default useHandleFavoriteGame;
