import { PagesDocument } from '@/generated';

import {
  extractGameAndProvider,
  normalizeFilteredGames,
  getGameTitleBySlug,
} from '@/hooks/games/utils';
import { FilteredGame, GameFilterSEOPayload } from '@/types/games_filters';
import { extractTranslations } from '@/utils/content';
import { fetchContent } from '@/utils/content-api';
import { Metadata } from 'next';
import { fallbackLng, languages } from '@/app/i18n/settings';
import { isMobile } from 'mobile-device-detect';
import { GAME_CATEGORIES } from '../constants';
import { APP_TITLE_TEMPLATE, METADATA_OBJECT } from '@/utils/constants';
import { Game } from '@/types/games';
import { generateCanonicalUrl } from '@/components/CanonicalItem/utils';
import client from '@/utils/api';
import config from '@/config';

export const getCategoryGame = (game: FilteredGame, categories: string[]) => {
  const gameCategory =
    game?.categories &&
    game?.categories.find((collection) => categories.includes(collection));
  switch (gameCategory) {
    case 'top':
      return 'Top';
    case 'fresh':
      return 'Fresh';
    case 'table-games':
      return 'Table';
    case 'bonus-buy':
      return 'Bonus Buy';
    case 'live-casino':
      return 'Live Casino';
    case 'skill-games':
      return 'Skill Games';
    case 'vip-games':
      return 'VIP';
    case 'blackjack':
      return 'Blackjack';
    case 'slots':
      return 'Slots Games';
    default:
      return '';
  }
};

export async function getGameMetadata(
  params: {
    lang: string;
    id: string;
  },
  searchParams: { [key: string]: string | string[] | undefined } = {},
): Promise<Metadata> {
  const queryLang = languages.includes(params.lang) ? params.lang : fallbackLng;
  const platform = isMobile ? 'mobile' : 'desktop';

  const pageData = await fetchContent(PagesDocument, { path: '/games/[id]' });
  const currentPage = pageData?.pages?.data?.[0]?.attributes;
  const pageContent = extractTranslations(queryLang, currentPage);

  const gameTitleSlug = getGameTitleBySlug(params.id);

  let gamesBySeo = await fetchGamesBySEO({
    game_seo_titles: [gameTitleSlug],
    device: platform,
  });

  let game = gamesBySeo?.[0] as FilteredGame;

  if (!game) {
    const fallbackGamesBySeo = await fetchGamesBySEO({
      game_seo_titles: [params?.id],
      device: platform,
    });
    game = fallbackGamesBySeo?.[0] as FilteredGame;
  }

  const { game: gameFromSlug, provider: providerFromSlug } =
    extractGameAndProvider(params.id);

  const finalGameTitle = game?.title || gameFromSlug;
  const finalProvider = game?.provider || providerFromSlug;
  const categoryGame = game
    ? getCategoryGame(game as any, GAME_CATEGORIES)
    : '';

  const metaTitle = pageContent?.SEO?.title
    .replace('{{game}}', `${finalGameTitle} ${categoryGame}`)
    .replace('{{provider}}', finalProvider);

  const metaDescription = pageContent?.SEO.description
    .replace('{{game}}', finalGameTitle)
    .replace('{{provider}}', finalProvider);

  const baseUrl = config.SITE_URL;

  return {
    ...METADATA_OBJECT,
    title: metaTitle,
    description: metaDescription,
    alternates: {
      canonical: game
        ? generateCanonicalUrl(game, baseUrl)
        : `${baseUrl}/games/${params.id}`,
    },
    openGraph: {
      ...METADATA_OBJECT.openGraph,
      title: {
        default: metaTitle,
        template: APP_TITLE_TEMPLATE,
      },
      description: metaDescription,
    },
  };
}

export const isFiatOnlyGame = (game: Game | FilteredGame) => {
  if (
    game?.identifier.includes('yggdrasil') ||
    game?.identifier.includes('relax') ||
    game?.provider == 'netent' ||
    game?.provider == 'thunderkick'
  ) {
    return true;
  }
  return false;
};

export const getStructuredDataGames = (game: FilteredGame) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Casino',
        item: `${config.SITE_URL}/casino`,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Providers',
        item: `${config.SITE_URL}/providers`,
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: game?.provider,
        item: `${config.SITE_URL}/providers/${game?.provider}`,
      },
      {
        '@type': 'ListItem',
        position: 4,
        name: game?.title,
        item: `${config.SITE_URL}/games/${game?.slug}`,
      },
    ],
  };
};

export const fetchGamesBySEO = async (payload: GameFilterSEOPayload) => {
  try {
    const res = await client(
      'games_filter/select_by_seo_titles',
      payload,
      'POST',
      {
        Accept: 'application/vnd.s.v2+json',
      },
    );
    const resArr = Object.keys(res).map((key) => res[key]);
    return normalizeFilteredGames(resArr);
  } catch (e) {
    console.log(e);
  }
};

export async function fetchFilteredGames(filter: any) {
  let result = await fetchGamesBySEO(filter);

  // If no results were found and `game_seo_titles` are provided, attempt a fallback
  if (!result?.length && filter?.game_seo_titles?.[0]) {
    result = await fetchGamesBySEO({
      game_seo_titles: [filter.game_seo_titles[0]],
    });
  }

  return result;
}
