import React from 'react';
import { useAccounts } from './accounts';
import { useMemo } from 'react';
import { useAppDispatch } from './redux';
import { setViewFiat } from '@/app/reducers/bootstrap';
import LeadingSelectItem from '@/components/Wallet/WalletModal/components/leading-select-item';
import { useAnalytics } from '@/hooks/use-analytics';
import { useScreenLayout } from '@/modules/games/context/screen-layout-context';

const useSelectItems = (gameViewIndex = 0) => {
  const dispatch = useAppDispatch();
  const { account: currentAccount } = useAccounts();
  const { toggleViewFiatForIndex } = useScreenLayout();
  const { track } = useAnalytics();

  const ITEMS = useMemo(() => {
    return [
      {
        id: currentAccount?.currency,
        component: <LeadingSelectItem noTitle item={currentAccount} />,
        onItemClick: () => {
          if (gameViewIndex === 0) {
            dispatch(setViewFiat(false));
            localStorage.fiat = 'false';
          } else {
            toggleViewFiatForIndex(gameViewIndex, false);
          }

          track('fiat_currency_toggled', {
            category: 'user',
            label: false,
            gameViewIndex,
          });
        },
      },
      {
        id: 'USD',
        component: <LeadingSelectItem noTitle item={'USD'} />,
        onItemClick: () => {
          if (gameViewIndex === 0) {
            dispatch(setViewFiat(true));
            localStorage.fiat = 'true';
          } else {
            toggleViewFiatForIndex(gameViewIndex, true);
          }

          track('fiat_currency_toggled', {
            category: 'user',
            label: true,
            gameViewIndex,
          });
        },
      },
    ];
  }, [currentAccount, dispatch, gameViewIndex, toggleViewFiatForIndex, track]);

  return ITEMS;
};

export default useSelectItems;
