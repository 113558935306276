import RestrictedComponent from '@/components/RestrictedComponent';
import { useAppSelector } from '@/hooks/redux';
import React, { useMemo } from 'react';
import { useScreenLayout } from '../../context/screen-layout-context';

interface RestrictedWrapperProps {
  gameViewIndex?: number;
}

const RestrictedWrapper = ({ gameViewIndex = 0 }: RestrictedWrapperProps) => {
  const { getGameForIndex } = useScreenLayout();

  const bootstrap = useAppSelector((state) => state.bootstrap);
  const secondaryGame = getGameForIndex(gameViewIndex);

  const gameState = useMemo(() => {
    if (gameViewIndex === 0) {
      return {
        isMinimized: bootstrap.isMinimized,
        gameView: bootstrap.gameViews?.[0] || null,
      };
    } else {
      return {
        isMinimized: false,
        gameView: {
          ...secondaryGame.game,
          status: secondaryGame.status,
        },
      };
    }
  }, [gameViewIndex, bootstrap, secondaryGame]);

  const { isMinimized, gameView } = gameState;

  if (gameView?.status !== 'restricted') {
    return null;
  }

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 z-[999] h-full w-full object-cover">
      <RestrictedComponent
        isMinimized={isMinimized}
        reason="game_restricted_in_country"
        className="h-full"
      />
    </div>
  );
};

export default RestrictedWrapper;
